define("discourse/plugins/discourse-poster-location/discourse/initializers/posterlocation", ["exports", "virtual-dom", "discourse/lib/plugin-api", "discourse/lib/ajax"], function (_exports, _virtualDom, _pluginApi, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializePosterLocation(api, siteSettings) {
    const posterlocation_enabled = siteSettings.posterlocation_enabled;
    if (!posterlocation_enabled) {
      return;
    }
    api.decorateWidget('poster-name:after', dec => {
      let result = 'none';
      if (dec.attrs && dec.attrs.userCustomFields && dec.attrs.userCustomFields.posterlocation) {
        result = dec.attrs.userCustomFields.posterlocation;
      }
      if (!result || result === 'none') {
        return;
      }
      return dec.h("i.fa.fa-map-marker.d-icon.d-icon-map-marker", [` ${result}`]);
    });
  }
  var _default = _exports.default = {
    name: 'posterlocation',
    initialize(container) {
      const siteSettings = container.lookup('site-settings:main');
      (0, _pluginApi.withPluginApi)('0.1', api => initializePosterLocation(api, siteSettings));
    }
  };
});